import Avro from '@avro/types';

import { CLIENT_KEY, SERVER_KEY } from './keys.js';

import * as schemas from './schemas/index.js';

function createSchema(name, value) {
  return Avro.Type.forSchema({
    type: "record",
    name: name,
    fields: [
      { type: "int", name: "k" },
      { type: value, name: "v" }
    ]
  });
};

const schema = {};

for (const type in schemas) schema[type] = createSchema(type, schemas[type]);

// Export them seperately.
export const {
  standard: standardSchema,
  init: initSchema,
  registry: registrySchema,
  event: eventSchema,
  sync: syncSchema
} = schema;

export const schemaSelector = (key, buffer = false) => {
  if (buffer) key = (key >>> 1) ^ -(key & 1);

  switch(key) {
    case CLIENT_KEY.SYNC:
    case CLIENT_KEY.TERRAIN_POLYGON:
      return schema['sync'];
    case CLIENT_KEY.DATA:
      return schema['registry'];
    case CLIENT_KEY.INIT:
      return schema['init'];
    case CLIENT_KEY.EVENT:
      return schema['event'];
    default:
      return schema['standard'];
  }
};
// let data = {
//   k: CLIENT_KEY.SYNC,
//   v: 0
// };

//
// data = {
//   k: 3,
//   v: {"string": "7.123"} //{ sync2: { id: "wtf" }}
// };

// let schemaRegistry = Avro.Type.forSchema({
//   name: "data",
//   type: "record",
//   fields: [
//     { name: "k", type: "int" },
//     {
//       name: "v",
//       type: [
//         "null",
//         "int",
// //        { name: "sync", type: "record", fields: [
// //          { name: "id", type: "string" },
// //          { name: "angle", type: "double" },
// //          { name: "bPosition", type: "double" },
// //          { name: "tPosition", type: "double" },
// //          { name: "cPosition", type: "double" },
// //          { name: "cAngle", type: "double" }
// //        ]},
//         { type: "array", items: [
//           "null", "boolean", "int", "string",
//           { type: "array", items: [
//             "int",
//             { type: "array", items: [
//               "int",
//               { type: "array", items: [
//                 "int",
//                 { type: "array", items: [
//                   "int"
//                 ]}
//               ]}
//             ]}
//           ]}
//         ]},
// //        { name: "registy", type: "record", fields: [
// //          { name: "data", type: {
// //            type: "map",
// //            values: [
// //              "null",
// //              {
// //                type: "array",
// //                items: [
// //                  "null",
// //                  "boolean",
// //                  "int",
// //                  "string",
// //                  {
// //                    type: "map",
// //                    values: [ "null", "boolean", "int", "string" ],
// //                  },
// //                  { type: "array", items: [ "null", "boolean", "int", "string" ] },
// //                ]
// //              },
// //              {
// //                type: "map",
// //                values: [ "null", "boolean", "int", "string" ],
// //              },
// //              "string",
// //              "int",
// //              "boolean",
// //            ]
// //          }}
// //        ]},
//         {
//           type: "map",
//           values: [
//             "null",
//             {
//               type: "array",
//               items: [
//                 "null",
//                 "boolean",
//                 "int",
//                 "string",
//                 {
//                   type: "map",
//                   values: [ "null", "boolean", "int", "string" ],
//                 },
//                 { type: "array", items: [ "null", "boolean", "int", "string" ] },
//               ]
//             },
//             {
//               type: "map",
//               values: [ "null", "boolean", "int", "string" ],
//             },
//             "string",
//             "int",
//             "boolean",
//           ],
//         },
//         "boolean",
//         "string"
//       ],
//       default: null
//     }
//   ]
// });

// let schemaRegistry2 = Avro.Type.forSchema({
//   name: 'data',
//   type: 'record',
//   fields: [
//     { name: 'k', type: 'int' },
//     { name: 'v', type: [ "null",
//       { name: "sync", type: "record", fields: [
//         { name: "id", type: [ "null", "string" ], default: null },
//         { name: "angle", type: [ "null", "double" ], default: null }
//       ]},
//       { name: "sync2", type: "record", fields: [
//         { name: "id2", type: [ "null", "string" ], default: null },
//         { name: "angle2", type: [ "null", "double" ], default: null }
//       ]}
//     ], default: null }
//   ]
// });
//
//
// let buffer = schemaRegistry.toBuffer(data);
// console.log(buffer);
//
// buffer = schemaRegistry.fromBuffer(buffer);
//
// console.log(buffer);

//

// const TIMES_TO_RUN = 100000;
// let now = new Date;

// console.log(now);

// let then2 = new Date;

// for (let step2 = 0, buffer2; step2 < TIMES_TO_RUN; step2++) {
//   buffer2 = schemaRegistry.toBuffer(data);
//   buffer2 = schemaRegistry.fromBuffer(buffer2);
// }

// let now2 = new Date;

// console.log(now2 - then2);

// let then3 = new Date;

// for (let step3 = 0, buffer3; step3 < TIMES_TO_RUN; step3++) {
//   buffer3 = defaultSchema.toBuffer(data);
//   buffer3 = defaultSchema.fromBuffer(buffer3);
// }

// let now3 = new Date;

// console.log(now3 - then3);
