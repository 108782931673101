export const standard = [
  "null",
  "int",
  "boolean",
  "string",
  { type: "array", items: [
    "null",
    "boolean",
    "int",
    "string"
  ]},
  { type: "map", values: [
    "null",
    {
      type: "array",
      items: [ "null", "boolean", "int" ]
    }
  ]}
];