<script setup>
import { onMounted, inject, ref } from 'vue';

import store from '../store';

import Form from './Form.vue';

// import Tetris from './Tetris.vue';

import {
  TabGroup,
  TabList,
  Tab,
  TabPanels
} from '@headlessui/vue';

const tabs = ref([
  'Login',
  'Create',
  'Forgot'
]);

const colors = ref([
  'text-sky-400/95',
  'text-emerald-400/95',
  'text-lime-400/95'
]);
</script>

<template>
  <div class="flex-auto m-auto text-center">
    <h1 class="font-bold mb-3">Welcome to the Warfare!</h1>
    <div class="
      inline-flex box-border
      divide-x divide-solid
    ">
      <a class="bg-slate-900/5 hover:bg-slate-100/5 active:bg-slate-50/10 px-6 py-2 transition"
      href="#about" title="About AerialDrop">About</a>
      <a class="bg-slate-900/5 hover:bg-slate-100/5 active:bg-slate-50/10 px-6 py-2 transition"
      href="#news" title="News Regarding AerialDrop">News</a>
      <a class="bg-slate-900/5 hover:bg-slate-100/5 active:bg-slate-50/10 px-6 py-2 transition"
      href="#manual" title="Gameplay Manual">Manual</a>
      <a class="bg-slate-900/5 hover:bg-slate-100/5 active:bg-slate-50/10 px-6 py-2 transition"
      href="#credits" title="Development Credits">Credits</a>
    </div>
  </div>
  <TabGroup as="div" class="flex-auto m-auto" :selectedIndex="Math.abs(store.form)" @change="(index) => store.form = index">
    <div class="box-border m-4">
      <TabList class="flex">
        <Tab v-slot="{ selected }" v-for="(tab, index) in tabs" as="template" class="
          flex-auto hover:bg-slate-300/5 p-3
          transition
        ">
          <button :class="[selected ? 'bg-slate-50/5 ' + colors[index] : 'bg-slate-700/5 ' + colors[index]]">
            {{ tab }}
          </button>
        </Tab>
      </TabList>
      <TabPanels v-slot="{ selectedIndex }" class="bg-slate-50/5">
        <Transition mode="out-in" appear>
          <Form :type="store.form" :key="store.form" :tabs="tabs"></Form>
        </Transition>
      </TabPanels>
    </div>
  </TabGroup>
</template>

<style scoped>
.v-enter-active {
  transition: all .2s ease-in;
}
.v-leave-active {
  transition: all .2s ease-out;
}

.v-enter-from,
.v-leave-to {
  filter: invert(100%);
  opacity: 0;
}
</style>
