import { createApp } from 'vue';

import App from './App.vue';
import './index.css';

import ws from './services/ws';

createApp(App)
.use(ws, {
  url: 'wss://aerialdrop.com:3001'
})
.mount('#app');
