<script setup>
import { ref } from 'vue';

import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue';

import { close } from './close.js';

import About from './About.vue';
import Credits from './Credits.vue';
import Manual from './Manual.vue';
import News from './News.vue';
import Privacy from './Privacy.vue';

const props = defineProps({
  invert: Boolean
});

const routes = {
  '#about': About,
  '#credits': Credits,
  '#manual': Manual,
  '#news': News,
  '#privacy': Privacy
};

const current = ref();
const show = ref(false);

const setRoute = (event) => {
  const route = routes[window.location.hash];
  if (route) {
    current.value = route;
    if (show.value) return;
  }

  show.value = !show.value;
}

if (window.location.hash.length) setRoute();
window.addEventListener('popstate', setRoute);
</script>

<template>
  <TransitionRoot as="template" :show="show">
    <Dialog as="div" class="fixed flex z-10 inset-0 overflow-y-auto px-8 py-4" @close="close">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <DialogOverlay class="fixed inset-0 bg-neutral-900 bg-opacity-75 transition-opacity backdrop-blur" />
      </TransitionChild>

      <TransitionChild @beforeEnter="changeData"
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0 translate-y-0 scale-95"
        enter-to="opacity-100 translate-y-0 scale-100"
        leave="ease-in duration-200"
        leave-from="opacity-100 translate-y-0 scale-100"
        leave-to="opacity-0 translate-y-0 scale-95"
      >
        <component :is="current" :class="{ invert: lightMode }" />
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>
