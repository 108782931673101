export const registry = {
  type: "map",
  values: [
    "null",
    "boolean",
    "double",
    "string",
    {
      type: "array",
      items: [
        "null",
        "boolean",
        "int",
        "string",
        {
          type: "map",
          values: [ "null", "boolean", "int", "string" ],
        },
        { type: "array", items: [ "null", "boolean", "int", "string" ] }
      ]
    },
    {
      type: "map",
      values: [ "null", "boolean", "int", "string" ],
    }
  ],
  defualt: null
};
