<script setup>
import { onMounted, inject, ref } from 'vue';

import MainMenu from './MainMenu.vue';
import Queue from './Queue.vue';
import Inbox from './Inbox.vue';
import Allies from './Allies.vue';
import Volume from './Volume.vue';

import {
  TabGroup,
  TabList,
  Tab,
  TabPanels
} from '@headlessui/vue';

import {
  InboxIcon,
  SpeakerWaveIcon,
  UserGroupIcon
} from '@heroicons/vue/24/outline';

import store from '../store';

const { isDark } = useData();

const tabs = ref([
  'Garage',
  'Team',
  'Store'
]);

const colors = ref([
  'text-sky-400/95',
  'text-emerald-400/95',
  'text-lime-400/95'
]);
</script>

<template>
  <div class="flex-auto m-auto w-full text-center">
    <div class="flex justify-between mx-8">
      <MainMenu/>
      <Queue/>
      <div class="w-5">
        <Inbox/>
        <Allies/>
        <Volume/>
      </div>
    </div>
  </div>
  <TabGroup as="div" class="flex-auto" :selectedIndex="1">
    <div class="box-border m-4">
      <TabList class="flex">
        <Tab v-slot="{ selected }" v-for="(tab, index) in tabs" as="template" class="
          flex-auto hover:bg-slate-300/5 p-3
          transition
        ">
          <button :class="[selected ? 'bg-slate-50/5 ' + colors[index] : 'bg-slate-700/5 ' + colors[index]]">
            {{ tab }}
          </button>
        </Tab>
      </TabList>
      <TabPanels v-slot="{ selectedIndex }" class="bg-slate-50/5">
        <Transition mode="out-in" appear>
          <Interface :type="selectedIndex" :key="selectedIndex" :tabs="tabs"></Interface>
        </Transition>
      </TabPanels>
    </div>
  </TabGroup>
</template>

<style scoped>
.v-enter-active {
  transition: all .2s ease-in;
}
.v-leave-active {
  transition: all .2s ease-out;
}

.v-enter-from,
.v-leave-to {
  filter: invert(100%);
  opacity: 0;
}
</style>