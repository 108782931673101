import AD from '../include/AD';

import store from '../store';

import { CLIENT_KEY, EVENT_KEY } from '../../../shared/keys';

import { standardSchema, schemaSelector } from '../../../shared/schema';

let ws;
let reconnectInterval = 1000;

const socket = (options) => {
  if (typeof options !== 'object') return;

  ws = new WebSocket(options.url);
  ws.binaryType = 'arraybuffer';

  ws.onopen = () => reconnectInterval = options.reconnectInterval || 1000;

  ws.onmessage = (event) => {
    let data, buffer, schema;

    try {
      buffer = Buffer.from(event.data);
      // Read the key to know what deserializer to use, have 256 options between -128 & 128.
      schema = schemaSelector(buffer[0], true);
      data = schema.fromBuffer(buffer);
    } catch (e) {
      console.log(e);
      return e;
    }

    // Key and Value always need to be defined.
    if (data.k === undefined || data.v === undefined) return;

    switch (data.k) {
      // Game
      // !--!
  //     case CLIENT_KEY.DATA:
  //       for (const [key, value] of Object.entries(data.v)) {
  //         if (!AD.eventEmitter) return AD[key] = value;
  //
  //         switch (key) {
  //           case 'player':
  //             switch (AD.scene) {
  //               case 'Lobby':
  //                 AD.eventEmitter.emit('usercards', value);
  //                 break;
  //
  //               case 'TerrainSelection':
  //                 for (let i = 0; i < AD.space; i++) {
  //                   AD.eventEmitter.emit('visualTerrain', value, i);
  //                 }
  //                 break;
  //
  //               case 'MechSelection':
  //                 for (let i = 0; i < AD.space; i++) {
  //                   AD.eventEmitter.emit('visualMech', value, AD.turnList, i);
  //                 }
  //                 break;
  //             }
  //             break;
  //
  //           case 'timer':
  //             AD.eventEmitter.emit('setTimer', value);
  //             break;
  //
  //           case 'turn':
  //             AD.eventEmitter.emit('gameCardsUpdate', AD.player, value);
  //             break;
  //
  //           case 'result':
  //             if (value === null) break;
  //
  //             AD.eventEmitter.emit('finished', value[0], value[1]);
  //             break;
  //
  //           case 'round':
  //             AD.eventEmitter.emit('setRound', value);
  //             break;
  //
  //           case 'wind':
  //             AD.eventEmitter.emit('setWind', value);
  //             break;
  //         }
  //
  //         // Set value at end so the events know past value.
  //         AD[key] = value;
  //       }
  //       break;
  //
  //     case CLIENT_KEY.CHAT:
  //       if (!data.v[1]) AD.chatEnabled = true;
  //       if (!AD.eventEmitter) break;
  //
  //       AD.eventEmitter.emit('chat', data.v);
  //       break;
  //
  //     case CLIENT_KEY.LATENCY:
  //       if (!AD.eventEmitter) break;
  //
  //       AD.eventEmitter.emit('lagging', data.v);
  //       break;
  //
  //     case CLIENT_KEY.EVENT:
  //       if (!AD.eventEmitter) break;
  //
  //       for (let i = 0, len = data.v.length; i < len; i++) {
  //         // Have to keep checking or messages left behind in large loops will keep emitting events.
  //         if (!AD.eventEmitter) break;
  //         switch (data.v[i][0]) {
  //           case EVENT_KEY.INIT:
  //             AD.eventEmitter.emit('initialize', data.v[i]);
  //             break;
  //           case EVENT_KEY.DESTROY_MECH:
  //             AD.eventEmitter.emit('mechDestroy', data.v[i][1]);
  //             break;
  //           case EVENT_KEY.DISABLE_MECH:
  //             AD.eventEmitter.emit('mechDisable', data.v[i][1]);
  //             break;
  //           case EVENT_KEY.DAMAGE_MECH:
  //             AD.eventEmitter.emit('explosion', data.v[i][1], data.v[i][2], 0, 0);
  //           case EVENT_KEY.ENERGY_MECH:
  //           case EVENT_KEY.HEAL_MECH:
  //             AD.eventEmitter.emit('popup', data.v[i][1], data.v[i][2], data.v[i][0], data.v[i][3]);
  //             break;
  //           case EVENT_KEY.SYNC_MECH:
  //             AD.eventEmitter.emit('sync', data.v[i]);
  //             break;
  //           case EVENT_KEY.CREATE_BULLET:
  //             AD.eventEmitter.emit('bulletCreate', data.v[i][1], data.v[i][2], data.v[i][3]);
  //             break;
  //           case EVENT_KEY.DESTROY_BULLET:
  //             AD.eventEmitter.emit('bulletDestroy', data.v[i][1], data.v[i][2], data.v[i][3], data.v[i][4], data.v[i][5]);
  //             break;
  //           case EVENT_KEY.SYNC_BULLET:
  //             AD.eventEmitter.emit('bulletSync', data.v[i][1], data.v[i][2], data.v[i][3], data.v[i][4]);
  //             break;
  //           case EVENT_KEY.CREATE_SHIELD:
  //             AD.eventEmitter.emit('shield', true, data.v[i][1], data.v[i][2]);
  //             break;
  //           case EVENT_KEY.DESTROY_SHIELD:
  //             AD.eventEmitter.emit('shield', false, data.v[i][1]);
  //             break;
  //           case EVENT_KEY.CREATE_PBOT:
  //             AD.eventEmitter.emit('pBotCreate', data.v[i][1], data.v[i][2]);
  //             break;
  //           case EVENT_KEY.DESTROY_PBOT:
  //             AD.eventEmitter.emit('pBotDestroy', data.v[i][1]);
  //             break;
  //           case EVENT_KEY.SYNC_PBOT:
  //             AD.eventEmitter.emit('pBotSync', data.v[i][1], data.v[i][2], data.v[i][3]);
  //             break;
  //           // Events
  //           case EVENT_KEY.AERIALDROP:
  //             AD.eventEmitter.emit('aerialdrop');
  //             break;
  //           case EVENT_KEY.ATTACK:
  //             AD.eventEmitter.emit('attackButton', data.v[i][1], data.v[i][2], data.v[i][3]);
  //             break;
  //           case EVENT_KEY.COMBO:
  //             AD.eventEmitter.emit('combo', data.v[i][1], data.v[i][2]);
  //             break;
  //           case EVENT_KEY.DIRECTION:
  //             AD.eventEmitter.emit('direction', data.v[i][1], data.v[i][2]);
  //             break;
  //           case EVENT_KEY.ENERGY:
  //             AD.eventEmitter.emit('energy', data.v[i][1], data.v[i][2], true);
  //             AD.eventEmitter.emit('popup', data.v[i][1], data.v[i][3], data.v[i][0]);
  //             break;
  //           case EVENT_KEY.FUEL:
  //             AD.eventEmitter.emit('fuel', data.v[i][1], data.v[i][2]);
  //             break;
  //           case EVENT_KEY.HEAL:
  //             AD.eventEmitter.emit('heal', data.v[i][1], data.v[i][2]);
  //             break;
  //           case EVENT_KEY.HEALTH:
  //             AD.eventEmitter.emit('health', data.v[i][1], data.v[i][2]);
  //
  //             if (!data.v[i][3]) continue;
  //             AD.eventEmitter.emit('popup', data.v[i][1], data.v[i][2], data.v[i][0], data.v[i][3]);
  //             break;
  //           case EVENT_KEY.NEXT:
  //             AD.next = data.v[i][1];
  //             AD.eventEmitter.emit('next', data.v[i][1], data.v[i][2]);
  //             break;
  //           case EVENT_KEY.NICE_SHOT:
  //             AD.eventEmitter.emit('niceShot', data.v[i][1], data.v[i][2]);
  //             break;
  //           case EVENT_KEY.MESSAGE:
  //             AD.eventEmitter.emit('notification', data.v[i][3], data.v[i][2]);
  //             break;
  //           case EVENT_KEY.POWER:
  //             AD.eventEmitter.emit('power', data.v[i][1], data.v[i][2]);
  //             break;
  //           case EVENT_KEY.READY_SET_GO:
  //             AD.eventEmitter.emit('readySetGo', data.v[i][1]);
  //             break;
  //           case EVENT_KEY.SNAP:
  //             AD.eventEmitter.emit('snap', data.v[i][1], data.v[i][3]);
  //             break;
  //           case EVENT_KEY.SWITCHING:
  //             AD.eventEmitter.emit('turnAlert', data.v[i][1], data.v[i][2]);
  //             break;
  //           case EVENT_KEY.TELEPORT:
  //             AD.eventEmitter.emit('teleport', data.v[i][1]);
  //             break;
  //           case EVENT_KEY.TICK:
  //             AD.eventEmitter.emit('setTick', data.v[i][1], data.v[i][2]);
  //             break;
  //           case EVENT_KEY.WIND_CHANGE:
  //             AD.eventEmitter.emit('changeWind', data.v[i][1], data.v[i][2]);
  //             break;
  //           default:
  //             continue;
  //         }
  //       }
  //       break;
  //
  //     case CLIENT_KEY.TERRAIN_POLYGON:
  //       AD.terrainPolygon = data.v[0];
  //       AD.damage = data.v[1];
  //
  //       if (!AD.eventEmitter) break;
  //       AD.eventEmitter.emit('terrainDraw');
  //       break;
  //
      // User Interface
      // !------------!
      case CLIENT_KEY.MESSAGES:
        store.messages.push(...data.v.map(item => [ item, Date.now() + item ]));
        break;
  
      case CLIENT_KEY.MODES:
        store.modes = data.v;
        break;
  //
  //     case CLIENT_KEY.SELECTED:
  //       options.store.dispatch('userInterface/setSelected', data.v);
  //       break;
  //
  //     case CLIENT_KEY.JOIN:
  //       options.store.dispatch('userInterface/setQueue', false);
  //       options.store.dispatch('userInterface/setMatch', data.v[0]);
  //
  //       // How to know which id the user is.
  //       AD.id = data.v[1];
  //       break;
  //
  //     case CLIENT_KEY.QUEUE:
  //       options.store.dispatch('userInterface/setInQueue', data.v);
  //
  //       break;
  //
  //     case CLIENT_KEY.VOLUME:
  //       // Redis likes to send string values.
  //       if (data.v[2] === 'false') {
  //         data.v[2] = false;
  //       } else if (data.v[2] === 'true') {
  //         data.v[2] = true;
  //       }
  //
  //       options.store.dispatch('userInterface/setVBG', data.v[0]);
  //       options.store.dispatch('userInterface/setVFX', data.v[1]);
  //       options.store.dispatch('userInterface/setMute', data.v[2]);
  //       break;
  //
  //     case CLIENT_KEY.LEAVE:
  //       if (options.store.getters['userInterface/getLeave']) {
  //         options.store.dispatch('userInterface/setLeave', false);
  //       }
  //
  //       options.store.dispatch('userInterface/setMatch', false);
  //       break;
  //

      case CLIENT_KEY.FORM:
        console.log(data.v);
        store.form = data.v;
        break;

      case CLIENT_KEY.ACCESS: // Login / Logout
        store.access = data.v ? true : false;
        store.team = [ data.v ];
        break;

      default:

    }
  }

  ws.onclose = (event) => {
    // event.code 1000 is the close event.
    if (!event || event.code === 1000) return;

    // setTimeout(() => {
    //   window.location.reload(true);
    // }, 9999);

    // Logout if connection is closed.
    store.access = false;

    let maxReconnectInterval = options.maxReconnectInterval || 3000;

    setTimeout(() => {
      if (reconnectInterval < maxReconnectInterval) {
        reconnectInterval += 1000;
      }

      socket(options);
    }, reconnectInterval);
  }

  ws.onerror = (error) => {
    console.log(error);
    ws.close();
  }
}

export default { install: (app, options) => {
  app.provide('$wsConnect', socket(options))
  .provide('$wsDisconnect', () => ws.close())
  .provide('$wsSend', (data) => ws.send(standardSchema.toBuffer(data)));
}};
