<script setup>
import { inject, ref } from 'vue';

import { ChevronDoubleRightIcon } from '@heroicons/vue/24/outline';

import { FORM_KEY, SERVER_KEY } from '../../../shared/keys.js';

const send = inject('$wsSend');

const props = defineProps({
  type: Number,
  tabs: Array
});

const fields = [];

let label = '';

switch (props.type) {
  case FORM_KEY.FORGOT:
    fields.push('Username');
    fields.push('Email');
    label = 'Verify';
    break;
  case FORM_KEY.CREATE:
    fields.push('Username');
    fields.push('Email');
    label = 'Availability';
    break;
  case FORM_KEY.PASSWORD:
    fields.push('Password');
    fields.push('Re-type Password');
    label = 'Create Account';
    break;
  case FORM_KEY.LOGIN:
  default:
    fields.push('Username');
    fields.push('Password');
    label = 'Sign In';
}

const values = ref([]);
fields.forEach(() => values.value.push(''));

</script>

<template>
  <form class="p-8 relative" @submit.prevent="send({ k: SERVER_KEY[tabs[Math.abs(type)].toUpperCase()], v: values })">
    <div class="grid grid-cols-1 gap-6">
      <label v-for="(value, index) in fields">
        <input
          v-model="values[index]"
          :type="(value === 'Password' || value === 'Re-type Password') ? 'password' : 'text'"
          class="
            block w-full
            px-1 placeholder:px-1
            bg-transparent
            transition-all
            focus:outline-0
            focus:ring-0
            border-0 border-b border-slate-300/50 focus:border-slate-300/75
            placeholder:text-white placeholder:font-semibold placeholder:italic
            peer
          "
          :placeholder="value"
        />
        <div class="
          absolute
          font-semibold italic
          transition-all
          px-1 -mt-14 peer-placeholder-shown:-mt-8
          peer-placeholder-shown:invisible peer-placeholder-shown:opacity-0
          scale-90 peer-placeholder-shown:scale-100
        ">{{ value }}</div>
      </label>
      <button type="submit"
        class="group w-full box-border border-violet-300/90">
        <div class="
          w-full flex items-center py-2 px-4 transition
          bg-violet-900/5 hover:bg-violet-50/5 active:bg-violet-50/10
          text-violet-400/95 hover:text-violet-300/90
        ">
          <span class="flex-none">
            {{ label }}
          </span>
          <ChevronDoubleRightIcon
          class="
            w-4 ml-3 transition transform
            -translate-x-0 group-hover:translate-x-full group-active:scale-y-0
            opacity-80 group-hover:opacity-40 group-active:opacity-0
          "
          aria-hidden="true" />
        </div>
      </button>
    </div>
  </form>
</template>
