export const event = {
  type: "array",
  items: {
    type: "array", items: [
      "null",
      "boolean",
      "int",
      "string",
      {
        type: "map",
        values: [ "null", "double", "string" ]
      }
    ]
  }
};