// No message key can be duplicated between types because of the dictionary lookup.
const data = {
  ERROR: [
    { NOPE: "That does not work." },
    { SESSION: "Your session data expired." },
    { USERNAME_LENGTH: "Your username is not between 3 and 16 characters." },
    { USERNAME_ALPHANUMERIC: "Your username is not Alphanumeric." },
    { USERNAME_LOWERCASE: "Your username is not Lowercase." },
    { PASSWORD_LENGTH: "Your password is not between 12 and 48 characters." },
    { PASSWORD_ASCII: "Your password is not ASCII." },
    { PASSWORD_MATCH: "Your password did not match your re-type, try again." },
    { EMAIL_LENGTH: "Your email is not between 6 and 128 characters." },
    { EMAIL_REAL: "Your email is not a real email..." }
  ],
  WARNING: [
    { INSECURE_PASSWORD: "Your password is not secure." }
  ],
  SUCCESS: [
    { ACCOUNT_SUCCESS: "This account is available." },
    { CREATE_SUCCESS: "Your account has been created." },
    { LOGIN_SUCCESS: "Your logged in." },
    { FORGOT_SUCCESS: "Your password reset has been sent to your email." }
  ],
  INFO: [
    { MAINTENANCE: "AerialDrop is currently in maintenance mode." }
  ]
}

export const SPACING = 100;

export const TYPE = [];

export const MESSAGE = [];
export const MESSAGE_KEY = {};
// ERROR: 0-99
// WARNING: 100-199
// SUCCESS: 200-299
// INFO: 300-399
Object.entries(data).forEach(([key, messages], index) => {
  TYPE.push(key);
  let start = SPACING * index;
  for (const message of messages) {
    Object.entries(message).forEach(([name, content]) => {
      MESSAGE[start] = content;
      MESSAGE_KEY[name] = start++;
    });
  }
});

// messages[0] = "Umm...";
// messages[1] = "Information is missing...";
// messages[2] = "That username does not exist.";
// messages[3] = "You entered the wrong password too many times.";
// messages[4] = "That username does not exist.";
// messages[5] = "That username already exists.";
// messages[6] = "That email already exists.";
// messages[7] = "This account is currently logged in.";
// messages[8] = "Wrong password.";
// messages[9] = "Can not verify.";
// messages[10] = "Password could not be hashed...";
// messages[11] = "That email does not exist.";
// messages[12] = "Your username is not between 3 and 16 characters.";
// messages[13] = "Your username is not Alphanumeric.";
// messages[14] = "Your username is not Lowercase.";
// messages[15] = "Your password is not between 12 and 48 characters.";
// messages[16] = "Your password is not ASCII.";
// messages[17] = "Your email is not between 6 and 128 characters.";
// messages[18] = "Your email is not a real email...";
// messages[19] = "Volume Settings are missing.";

// export const MESSAGE_KEY = {
//   ERROR: -3,
//   WARNING: -2,
//   INFO: -1,
//   UMM: 0,
//   INFO_MISSING: 1,
//   USERNAME_NOT_EXIST: 2,
//   PASSWORD_WRONG_TIMES: 3,
//   USERNAME_NOT_EXIST: 4,
//   USERNAME_EXISTS: 5,
//   EMAIL_EXISTS: 6,
//   ALREADY_LOGGED: 7,
//   WRONG_PASSWORD: 8,
//   NO_VERIFY: 9,
//   PASSWORD_NO_HASH: 10,
//   EMAIL_NO_EXIST: 11,
//   USERNAME_LENGTH: 12,
//   USERNAME_ALPHANUMERIC: 13,
//   USERNAME_LOWERCASE: 14,
//   PASSWORD_LENGTH: 15,
//   PASSWORD_ASCII: 16,
//   EMAIL_LENGTH: 17,
//   EMAIL_REAL: 18,
//   VOLUME_SETTINGS: 19
// };