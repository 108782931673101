<script setup>
import { ref, toRefs } from 'vue';
import { TransitionRoot, TransitionChild } from '@headlessui/vue';

import store from '../store';

import { MESSAGE, SPACING, TYPE } from '../../../shared/messages.js';

import {
  InformationCircleIcon,
  ExclamationTriangleIcon,
  ExclamationCircleIcon,
  CheckCircleIcon
} from '@heroicons/vue/24/outline';

const props = defineProps({
  message: Array
});

const { message } = toRefs(props);

const shown = ref(true);

const remove = () => {
  if (id) clearTimeout(id);
  if (!shown.value) return;

  shown.value = false;

  setTimeout(() => {
    store.messages.splice(store.messages.findIndex(i => i[1] === message.value[1]), 1);
  }, 1000);
};

const id = setTimeout(remove, 7000);

let classes = 'bg-blue-800/90 border-blue-300/90';
let color = 'fill-blue-600/60';
let Icon = InformationCircleIcon;

switch (TYPE[Math.round(message.value[0] / SPACING)]) {
  case 'ERROR':
    classes = 'bg-red-800/90 border-red-300/90';
    color = 'fill-red-600/60';
    Icon = ExclamationTriangleIcon;
    break;
  case 'WARNING':
    classes = 'bg-yellow-800/90 border-yellow-300/90';
    color = 'fill-yellow-600/60';
    Icon = ExclamationCircleIcon;
    break;
  case 'SUCCESS':
    classes = 'bg-green-800/90 border-green-300/90';
    color = 'fill-green-600/60';
    Icon = CheckCircleIcon;
    break;
}
</script>

<template>
  <TransitionRoot
    appear
    :show="shown"
    @click="remove()"
    class="w-fit m-2 drop-shadow-xl p-2 box-border ml-auto pointer-events-auto"
    :class="classes"
    enter="transition ease-out duration-700 transform"
    enter-from="opacity-0 translate-x-full"
    enter-to="opacity-100 -translate-x-0"
    leave="transition ease-in duration-900 transform"
    leave-from="opacity-100 -translate-x-0"
    leave-to="opacity-0 translate-x-full"
  >
    <TransitionChild
      enter="transition ease-out duration-600 transform"
      enter-from="opacity-0 translate-x-full"
      enter-to="opacity-100 -translate-x-0"
      leave="transition ease-in duration-800 transform"
      leave-from="opacity-100 -translate-x-0"
      leave-to="opacity-0 translate-x-full"
    >
      <div class="flex">
        <div class="flex-none h-5 w-5 mr-2">
          <Icon :class="color" />
        </div>
        <div class="flex-initial">
          {{ MESSAGE[message[0]] }}
        </div>
      </div>
    </TransitionChild>
  </TransitionRoot>
</template>
