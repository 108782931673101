export const VERSION = "1.7.7";

export const CLIENT_KEY = {
  MESSAGES: 0,
  FORM: 1,
  ACCESS: 2,
  MODES: 3,
  JOIN: 4,
  LEAVE: 5,
  QUEUE: 6,
  VOLUME: 7,
  QUERIED: 8,
  SELECTED: 9,
  DATA: 10,
  CHAT: 11,
  INIT: 12,
  TERRAIN_POLYGON: 13,
  EVENT: 14,
  ACTION: 15,
  LATENCY: 16
};

export const FORM_KEY = {
  PASSWORD: -1,
  LOGIN: 0,
  CREATE: 1,
  FORGOT: 2
};

// export const CLIENT_KEY = {
//   MESSAGES: 0,
//   STEP_LOGIN: 1,
//   STEP_SIGNUP: 2,
//   STEP_FORGET: 3,
//   ACCESS: 4,
//   MODES: 5,
//   JOIN: 6,
//   LEAVE: 7,
//   QUEUE: 8,
//   VOLUME: 9,
//   QUERIED: 10,
//   SELECTED: 11,
//   DATA: 12,
//   CHAT: 13,
//   INIT: 14,
//   TERRAIN_POLYGON: 15,
//   EVENT: 16,
//   ACTION: 17,
//   LATENCY: 18
// };

export const SERVER_KEY = {
  LOGOUT: -1,
  LOGIN: -2,
  CREATE: -3,
  FORGOT: -4,
  VOLUME: -9,
  JOIN: -13,
  LEAVE: -14,
  QUEUE: -15,
  INIT: -16,
  READY: -17,
  TERRAIN_SELECTION: -18,
  MECH_SELECTION: -19,
  INPUT: -20,
  CHANGE_EMAIL: -21,
  CHANGE_PASSWORD: -22,
  INFO: -23,
  CREATE: -24,
  USER: -25,
  PARTY: -26,
  LOADED: -27,
  LATENCY: -28,
  TERMINATE: -29,
  BROADCAST: -30,
  CHAT: -31
};

export const EVENT_KEY = {
  WIND_CHANGE: -17,
  TICK: -16,
  TELEPORT: -15,
  SWITCHING: -14,
  SNAP: -13,
  READY_SET_GO: -12,
  POWER: -11,
  MESSAGE: -10,
  NICE_SHOT: -9,
  NEXT: -8,
  HEALTH: -7,
  HEAL: -6,
  FUEL: -5,
  ENERGY: -4,
  DIRECTION: -3,
  COMBO: -2,
  AERIALDROP: -1,
  INIT: 0,
  DESTROY_MECH: 1,
  DISABLE_MECH: 2,
  DAMAGE_MECH: 3,
  ENERGY_MECH: 4,
  HEAL_MECH: 5,
  SYNC_MECH: 6,
  CREATE_BULLET: 7,
  DESTROY_BULLET: 8,
  SYNC_BULLET: 9,
  CREATE_SHIELD: 10,
  DESTROY_SHIELD: 11,
  CREATE_PBOT: 12,
  DESTROY_PBOT: 13,
  SYNC_PBOT: 14
};

export const JOIN_KEY = {
  LOADED: 0,
  PLAYER: 1,
  SPECTATOR: 2
};

// export const INPUT_KEY = {
//   CHAT: 0,
//   POWER: 1,
//   ATTACK: 2,
//   SHOOT: 3,
//   LEFT: 4,
//   RIGHT: 5,
//   DOWN: 6,
//   UP: 7
// };

// export const INPUT_KEY = {
//   SHOOT: 0,
//   LEFT: 1,
//   RIGHT: 2,
//   DOWN: 3,
//   UP: 4
// };

export const INPUT_KEY = {
  SHOT: -1,
  BASIC: 0,
  UTILITY: 1,
  ULTIMATE: 2,
  WIND: 3,
  TELEPORT: 4,
  BUNKER: 5,
  SHIELD: 6,
  HEAL: 7,
  DOUBLE: 8,
  UP: 9,
  RIGHT: 10,
  DOWN: 11,
  LEFT: 12,
  // Any Value from 13-113 is a power amount, which also means shoot.
};

export const SCENE_KEY = {
  BOOT: -1,
  LOADING: 0,
  LOBBY: 1,
  TERRAIN_SELECTION: 2,
  MECH_SELECTION: 3,
  GAME: 4
};
