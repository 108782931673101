<script setup>
import { onMounted, inject, ref } from 'vue';

import { VERSION } from '../../shared/keys';

import { TransitionRoot } from '@headlessui/vue';

import { MoonIcon, SunIcon } from '@heroicons/vue/24/outline';

import store from './store';

import Public from './components/Public.vue';
import Private from './components/Private.vue';
import Modal from './components/Modal.vue';
import Toast from './components/Toast.vue';

const YEAR = new Date().getFullYear();

const lightMode = ref(false);

onMounted(() => {
  inject('$wsConnect');
});
</script>

<template>
  <TransitionRoot
    appear show
    class="
      flex flex-col h-screen shadow-inner hover:shadow-none overflow-auto
      bg-gradient-to-b from-gray-900 via-slate-900 to-zinc-900 transition
    "
    :class = "{ invert: lightMode }"
    enter="transition"
    enter-from="opacity-0 scale-y-0 scale-x-75"
    enter-to="opacity-100 scale-y-100 scale-x-100"
    leave="transition"
    leave-from="opacity-100 scale-y-100 scale-x-100"
    leave-to="opacity-0 scale-y-0 scale-x-75"
  >
    <header class="flex flex-row h-8 px-4 py-1">
      <div class="flex-auto text-left">
        <h6 class="font-extrabold">AerialDrop</h6>
      </div>
      <div class="flex-auto text-right">
        <a href="#privacy" class="single-link" title="Privacy Policy">Privacy</a>
      </div>
      <button @click="lightMode = !lightMode"
        class="w-5 hover:w-6
        transition-all ml-6"
        :title="(lightMode) ? 'Light Mode' : 'Dark Mode'"
      >
        <MoonIcon v-if="lightMode" title="Dark Mode" />
        <SunIcon v-else title="Light Mode" />
      </button>
    </header>
    <div class="flex flex-row flex-auto flex-wrap">
      <Transition>
        <component :is="(store.access) ? Private : Public" />
      </Transition>
    </div>
    <footer class="flex flex-row h-8 px-4 py-1">
      <div class="flex-auto text-left">
        <a href="https://madgizmo.com/" class="mr-1 single-link" title="MadGizmo" :class="{ invert: lightMode }">
          <span class="text-mad">Mad</span>
          <span class="text-gizmo">Gizmo</span>
        </a>
        2015 – {{ YEAR }}
      </div>
      <div class="flex-auto text-right">v{{ VERSION }}</div>
    </footer>
    <Modal :invert="lightMode" />
  </TransitionRoot>

  <div class="fixed bottom-7 right-1 z-20 pointer-events-none" v-if="store.messages.length">
    <Toast
      v-for="(message, index) in store.messages"
      :message="message"
      :key="message[1]"
    />
  </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
