export const sync = {
  type: "array", items: [
    "null",
    "boolean",
    "double",
    "string",
    { type: "array", items: [
      "double",
      {
        type: "map",
        values: [
          "null",
          "boolean",
          "double",
          "string",
          {
            type: "map",
            values: [ "null", "boolean", "double", "string" ],
          }
        ],
      },
      { type: "array", items: [
        "double",
        {
          type: "map",
          values: [ "null", "boolean", "double", "string" ],
        },
        { type: "array", items: [
          "double",
          {
            type: "map",
            values: [ "null", "boolean", "double", "string" ],
          },
          { type: "array", items: [
            "double",
            {
              type: "map",
              values: [
                "null",
                "boolean",
                "double",
                "string",
                {
                  type: "map",
                  values: [ "null", "boolean", "double", "string" ],
                }
              ],
            }
          ]}
        ]}
      ]}
    ]},
    {
      type: "map",
      values: [ "null", "boolean", "double", "string" ],
    }
  ]
};
