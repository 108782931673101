

export const init = {
  name: "initialize",
  type: "record",
  fields: [
    { type: "int", name: "player" },
    { type: {
      type: "array",
      items: {
        type: "record",
        name: "items",
        fields: [
          { type: {
            type: "record",
            name: "point",
            fields: [
              { name: "x", type: "double" },
              { name: "y", type: "double" }
            ]
          }, name: "position" },
          { type: "double", name: "angle" }
        ]
      }
    }, name: "parts" },
    { name: "direction", type: "int" },
    { name: "switching", type: "boolean" },
    { name: "power", type: { type: "record", fields: [
      { name: "set", type: "int" },
      { name: "max", type: "int" }
    ]}},
    { name: "fuel", type: { type: "record", fields: [
      { name: "set", type: "int" },
      { name: "max", type: "int" }
    ]}},
    { name: "health", type: { type: "record", fields: [
      { name: "set", type: "int" },
      { name: "max", type: "int" }
    ]}},
    { name: "energy", type: { type: "record", fields: [
      { name: "set", type: "int" },
      { name: "max", type: "int" }
    ]}},
    { name: "attack", type: "int" }
  ]
};