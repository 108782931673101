import { reactive } from 'vue';

const store = reactive({
  access: false,

  form: 0,

  login: ['', ''], // username, password
  create: ['', ''], // username, email
  forgot: ['', ''], // username, email

  messages: [],

  sidebar: false,
  leave: false,
  news: false,
  manual: false,
  story: false,
  credits: false,
  settings: false,
  vbg: 10,
  vfx: 10,
  mute: false,
  modes: {},
  match: false,
  team: [],
  queue: false,
  inQueue: false,
  queueCount: {},
  selected: [],
  validationErrors: []
});

export default store;
